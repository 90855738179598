import React, {useEffect, useRef} from "react";
import {DepositsUserGET} from "../../model/delivery";
import {Empty, List} from "antd-mobile";
import {timestampFormat} from "../../utils";

export default  function (){
    const firstRenderRef = useRef(true);
    const [li,setLi] = React.useState([]);
    useEffect(() => {
        if(firstRenderRef.current){
            DepositsUserGET().then(async r => {
                let data = await r.json()
                console.log(data)
                setLi(data)
            })
            firstRenderRef.current = false;
            return;
        }
    }, []);

    return <div>
        {li.length?li.map(item=> <List>
                <List.Item title={timestampFormat(item.updated)}  children={`提现金额 :${item.money} ¥`} extra={item.settle?"已到账":"未到账"}/>
        </List>):<Empty description='暂无数据' />}
    </div>
}