import {Skeleton} from "antd-mobile";

export const wx = window.wx
export const appId = 'wx05efd4b0a74d2f95'
// export  const api = "http://localhost:3000/"
export  const api = "https://api.xldkeji.com/"

export const billPlan = (plan)=>{
    console.log(plan)
    switch (plan){
        case 0:
            return '等待骑手接单'
        break;
        case 1:
            return '备货中'
            break;
        case 2:
            return '已出货'
            break;
        case 3:
            return '配送中'
            break;
        case 4:
            return '已送达'
            break;
        case 5:
            return '已取走'
            break;
    }
}
export function SkeletonDIV() {
    return <div>
        <Skeleton.Title animated/>
        <Skeleton.Paragraph lineCount={5} animated/>
    </div>
}


export const urlEncode = (str) =>{
    str = (str + '').toString();
    return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
    replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
}

//打开微信授权登陆
export function openWxLogin(){
    let url = window.location.href
    let appId = 'wx05efd4b0a74d2f95'
    let url_call = urlEncode(url)
    let open = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${url_call}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
    window.location = open
    console.log('通过微信登陆',url,open)
}

//微信登陆
export const wxLogin =(code,scope) => {
    let obj = {
        code:code,
        scope:scope
    }
    fetch(api+'wx_login', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    }).then(async res => {
        let user = await res.text()
        localStorage.setItem('token',user)
        localStorage.setItem('scope',scope)
        console.log('user',user)
    })
}

//微信鉴权
export const wxReady = (_url)=>{
    let loginStr = {
        nonceStr:Math.random().toString(36).substring(2),
        timestamp:Math.round(new Date().getTime() / 1000).toString(),
        url:_url
    }
    // console.log('登陆鉴权',loginStr)
    fetch(api+'wx_ready',{
        method:"POST",
        headers:{
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(loginStr)
    }).then(async r=>{
        let configData = await r.json()
        console.log('鉴权信息',configData)
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId , // 必填，公众号的唯一标识
            timestamp:configData.timestamp, // 必填，生成签名的时间戳
            nonceStr:configData.nonceStr, // 必填，生成签名的随机串
            signature:configData.signature,// 必填，签名
            jsApiList:[
                'openAddress',  //收货地址
                'scanQRCode',   //扫一扫
                'uploadImage',       //上传图片
                'chooseImage',  //拍照或从手机相册中选图接口
                'updateTimelineShareData',  //分享接口
                'chooseWXPay',  //微信支付
                'closeWindow',      //关闭当前窗口
                'checkJsApi'        //判断当前客户端版本是否支持指定 JS 接口
            ]
        })
        setTimeout(()=>{
            wx.ready(function(){
                // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。console.log('config成功')
                console.log('验证成功')
            });

            wx.error(function(err){
                console.log('config失败',err)
                // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            });
        },1000)
    })
}

// 微信支付
export const  wxPay = (order)=>fetch(api+'pay',{
    method:"POST",
    headers:{
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(order)
}).then(async res => await res.json())

export function timestampFormat( timestamp ) {
    function zeroize( num ) {
        return (String(num).length == 1 ? '0' : '') + num;
    }

    var curTimestamp = parseInt(new Date().getTime() / 1000); //当前时间戳
    var timestampDiff = curTimestamp - Date.parse(timestamp)/1000 ; // 参数时间戳与当前时间戳相差秒数

    var curDate = new Date( curTimestamp * 1000 ); // 当前时间日期对象
    var tmDate = new Date( Date.parse(timestamp)/1000 * 1000 );  // 参数时间戳转换成的日期对象

    var Y = tmDate.getFullYear(), m = tmDate.getMonth() + 1, d = tmDate.getDate();
    var H = tmDate.getHours(), i = tmDate.getMinutes(), s = tmDate.getSeconds();

    if ( timestampDiff < 60 ) { // 一分钟以内
        return "刚刚";
    } else if( timestampDiff < 3600 ) { // 一小时前之内
        return Math.floor( timestampDiff / 60 ) + "分钟前";
    } else if ( curDate.getFullYear() == Y && curDate.getMonth()+1 == m && curDate.getDate() == d ) {
        return '今天' + zeroize(H) + ':' + zeroize(i);
    } else {
        var newDate = new Date( (curTimestamp - 86400) * 1000 ); // 参数中的时间戳加一天转换成的日期对象
        if ( newDate.getFullYear() == Y && newDate.getMonth()+1 == m && newDate.getDate() == d ) {
            return '昨天' + zeroize(H) + ':' + zeroize(i);
        } else if ( curDate.getFullYear() == Y ) {
            return  zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
        } else {
            return  Y + '年' + zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
        }
    }
}


export function GetUrlRelativePath() {
    let url = document.location.toString();
    let arrUrl = url.split("/#");
    let start = arrUrl[1].indexOf("/");
    let relUrl = arrUrl[1].substring(start);//stop省略，截取从start开始到结尾的所有字符
    if (relUrl.indexOf("?") != -1) {
        relUrl = relUrl.split("?")[0];
    }
    return relUrl;
}

export function GetUrlPara() {
    let url = document.location.toString();
    let arrUrl = url.split("?");
    let para = arrUrl[1];
    return para;
}