import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {OrderIdGET} from "../../model/user";
import {Empty, Steps} from "antd-mobile";
const {Step} = Steps

export default function (){
    const params = useParams()
    const [data,setData] = useState({})
    useEffect(()=>{
        console.log(params.id)
        OrderIdGET(params.id).then(async r => {
            if (r.status == 200) {
                let data = await r.json()
                setData(data)
                console.log(data)
            }
        })
    },[])
    return <div>
        {data._id?<Steps direction='vertical' className={'orderBottom'}>
            <Step
                title= {`单号:${data.code}`}
                status='wait'
                description={`金额: ${data.money}`}
            />
            {data.bill.map(item=><Step
                title= {`${item.store}-购买${item.count} 件/${data.money}元`}
                status='wait'
                description={item.goods.map(goods => <p style={{margin:0}}>{goods.title +'-'+ goods.text} *  {goods.count}</p>)}
            />)}

        </Steps>:<Empty description='暂无数据'/>}

    </div>
}