import {Ellipsis, Image, Picker} from "antd-mobile";
import {AddCircleOutline} from "antd-mobile-icons";
import {useContext, useEffect, useState} from "react";
import sale from "../img/scheme.png"
import que from  "../img/quehuo.png"
import {ShoppingGETNumber, ShoppingPOST} from "../model/user";
import {User} from "../pages/user";
import {Login} from "./login";
import {useSearchParams} from "react-router-dom";
import {openWxLogin} from "../utils";

export default function (props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const {shoppingCount, setShoppingCount} = useContext(User)
    const {obj,tel} = props
    const [columns,setColumns] = useState([])
    const [visible,setVisible] = useState(false)
    //编辑规格选择数据
    function onGoods(e){
        let token = localStorage.getItem("token")
        if(token){
            let imgArr = []
            obj.price.map((item,key)=>{
                imgArr.push({
                    label: `${item.price} ¥ / ${item.size}`,
                    value: key,
                },)
            })
            setColumns([imgArr])
            setVisible(true)
        }else {
            // Login()
            openWxLogin()
        }

    }
    return <div className='goods' onClick={() =>onGoods(obj)}>
        <div className='goods_img'>
            <Image className='goods_imgA' src={obj.imgArr[0].url}/>
            {/*{1 ? <Image className='goods_imgB'  src={que}/> : ''}*/}
        </div>
        <div>
            <div className='goodsTitle'>
                <Ellipsis direction='end' rows={2} content={obj.title}/></div>
            <div className='goods_bar'>
                <div className='goods_bar_t1'>¥ <span
                    className='goods_bar_t2'>{obj.price[0].price}</span>/{obj.price[0].size}</div>
                <AddCircleOutline style={{marginRight: '5px'}} fontSize={18}/>
            </div>
        </div>
        <Picker columns={columns} visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                onConfirm={(v,i) => {
                    let goods = {
                        goodsId:obj._id,
                        storeId:obj.store,
                        tel:tel,
                        scope:Number(searchParams.get('scope')),
                        priceKey:i.items[0].value,
                        count:1
                    }
                    ShoppingPOST(goods).then(async r => {
                        console.log(11, await r.json())
                        setShoppingCount(shoppingCount+1)
                    })
                    console.log("添加购物车商品",goods)
                }}/>
    </div>
}