import Goods from "../../component/goods";
import { useThrottleFn } from 'ahooks'
import {SideBar,SearchBar} from "antd-mobile";
import {useEffect, useRef, useState} from "react";
import {GoodsLi, StoreLi, UserMsg, UserScope} from "../../model/user";
import {SkeletonDIV, wx} from "../../utils";
import {useParams, useSearchParams} from "react-router-dom";
const Height = window.innerHeight-60

export  default  function (){
    const [store, setStore] = useState([]) //店铺列表
    const [goods,setGoods] = useState([])   //商品列表
    const [ikey,setIkey]= useState(0) //默认店铺
    const [activeKey, setActiveKey] = useState(1)
    const firstRenderRef = useRef(true);
    const [searchParams, setSearchParams] = useSearchParams();
    function goodsData(id){
        let Id = id.substring(0,24)
        let i =id.substring(id.length-1,id.length);
        GoodsLi(Id).then(async g => {
            let goods = await g.json()
            console.log('选中店铺商品',goods)
            setGoods(goods)
        })
        setIkey(Number(i))
        console.log("选择Key",i)
    }

    useEffect(()=>{
        if(firstRenderRef.current){
            let scope =  searchParams.get('scope')
            if(localStorage.getItem('token')){
                UserMsg().then(async r => {
                    if(r.status != 200){
                        localStorage.removeItem('token')
                        return
                    }
                    console.log('用户信息',await r.json())
                })
            }

            console.log('位置',scope)
            localStorage.setItem('scope',scope?scope:0)
            StoreLi(scope?scope:0).then(async r => {
                let data = await r.json()
                setStore(data)
                console.log('店铺列表',data)
                if(data.length){
                    goodsData(data[0]._id+ikey)
                }
            })
            firstRenderRef.current = false;
            return;
        }
    },[])
    return <div className='Home' style={{height: Height}}>
        <div className='homeBar'>
            <SearchBar placeholder='请输入内容'/>
        </div>
        <div className='HomeGoods'>
            {store.length ? <SideBar style={{'--item-border-radius': '0px', height: Height - 57}}
                                     onChange={(key) => goodsData(key)}>
                {store.map((item, i) => (
                    <SideBar.Item key={item._id + i} title={item.name}/>
                ))}
            </SideBar> : <SkeletonDIV/>}

            <div className="homeRow">
                {store.length ? <img className="homeFm" src={store[ikey].fm} alt=""/> :<SkeletonDIV/>}

                {goods.length ? <div style={{height: Height - 60}}>
                    {goods.map((obj, i) => <Goods key={"goods" + i} obj={obj} tel={store[ikey].tel}/>)}
                    <div className={"goods"}></div>
                </div> : ''}
            </div>

        </div>
    </div>
}


// ssh -L 3001:localhost:3001 root@114.132.75.251
