import {Outlet, useNavigate} from "react-router-dom";
import {
    BillOutline,
    EditSFill,
    EnvironmentOutline, FillinOutline,
    HeartOutline,
    LocationFill,
    TeamFill,
    UserOutline
} from "antd-mobile-icons";
import {TabBar} from "antd-mobile";
import React, {useContext, useEffect} from "react";
import {Store} from "../../model/store";
import {GetUrlPara, GetUrlRelativePath} from "../../utils";
export default  function(){

    const navigate = useNavigate();
    const [rKey, setrKey] = React.useState('');
    useEffect(() => {
        let urlKey = `${GetUrlRelativePath()}`
        setrKey(urlKey)
        document.title="商户中心"
    }, []);
    let tabs = [
        {
            key: '/store',
            title: '订单',
            icon: (active)=> <BillOutline />
        },
        {
            key: '/store/goods',
            title: '货架',
            icon: (active) =><FillinOutline />,
        },
        {
            key: '/store/me',
            title: '我的',
            icon: (active) =><UserOutline />,
        },
    ]
    return <div>
        <Outlet/>
        <TabBar  className='AppTabBar' activeKey={rKey} onChange={(value)=> {
            setrKey(value)
            navigate(value)
        }}>
            {tabs.map(item => (
                <TabBar.Item
                    key={item.key}
                    icon={item.icon}
                    title={item.title}
                    badge={item.badge}
                />
            ))}
        </TabBar>
    </div>
}

