import {List, Button, Empty} from "antd-mobile";
import React, {useEffect, useState} from "react";
import {DepositsOrderGET, DepositsOrderPOST} from "../../model/delivery";
import {timestampFormat} from "../../utils";


// "proxy": "http://localhost:3001",

export default function () {
    const [load, setLoad] = useState(1)
    let [li, setLi] = useState([])
    let [orderArr, setOrderArr] = useState([])
    let [money, setMoney] = useState(0)
    useEffect(() => {
        DepositsOrderGET().then(async r => {
            let data = await r.json()
            console.log(data)
            let m = 0
            let o = []
            data.map(async r => {
                m += r.deliveryMoney
                o.push(r._id)
            })
            setMoney(m)
            setOrderArr(o)
            console.log(m, o)
            setLi(data)
        })
    }, [load])
    return <div>
        {li.length ? <List>
            {li.map(item => <List.Item title={`单号:${item.code}`} description={<GoodsLi data={item.bill}/>}
                                       children={`配送费:${item.deliveryMoney}¥`}
                                       extra={timestampFormat(item.updated)}/>)}
        </List> : <Empty description='暂无数据'/>}
        {li.length ? <div className={'deliveryBtn'}>
            <Button style={{width: '96%'}} block color='primary' size='large' onClick={() => {
                DepositsOrderPOST({orderArr, money, type: 2, scope: Number(localStorage.getItem('scope'))}).then(r => {
                    console.log(111, r)
                    if (r.status == 200) {
                        setLoad(load + 1)
                    }
                })
            }}>
                可提现{orderArr.length}单,合计{money}元
            </Button>
        </div> : ''}


    </div>
}

function GoodsLi(props) {
    const {data} = props
    return <div className={'orderGoodsLi'}>
        {data.map(item => <p>{item.store} * {item.count} </p>)}
        {/*<p>终点:</p>*/}
    </div>
}