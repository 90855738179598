import {Button, Checkbox, Image, List, Space, SwipeAction,Empty} from "antd-mobile";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {OrderPOST, ShoppingDELETE, ShoppingGET, ShoppingGETNumber, ShoppingPATCH} from "../../model/user";
import {User} from "./index";
import {SkeletonDIV} from "../../utils";

function GoodsImg(props) {
    return <Image
        src={props.url}
        style={{borderRadius: 3, marginLeft: 10}}
        fit='cover'
        width={50}
        height={50}
    />
}

// onClick={() => {
//     let _a;
//     (_a = checkboxRef.current) === null || _a === void 0 ? void 0 : _a.toggle();
// }}

const ListItemWithCheckbox = props => {
    const checkboxRef = useRef(null);
    const rightActions = [{
        key: 'delete', text: '删除', color: 'danger',
    },];
    const {item, onCount, rmCount} = props
    const [count, setCount] = useState(item.count)
    return (<SwipeAction key={item.id} rightActions={rightActions} onAction={(e) => {
        if (e.key = "delete") {
            checkboxRef.current.uncheck()
            rmCount(item)
        }
    }}>
        <List.Item prefix={<div style={{display: "flex"}}>
            <Checkbox value={item} ref={checkboxRef}/>
            <GoodsImg url={item.imgArr[0].url}/>
        </div>}  arrow={false} description={`${item.price[item.priceKey].size}/${item.price[item.priceKey].price}¥`}
                   children={item.title}
                   extra={<div className={'Stepper'}>
                        <span onClick={(e) => {
                            e.stopPropagation()
                            if(count == 1){
                                rmCount(item)
                            }else {
                                ShoppingPATCH(item.id, -1).then(r => {
                                    if (r.status == 200) {
                                        setCount(count - 1)
                                        onCount(item.id, count - 1)
                                    }
                                })
                            }
                        }}>-</span>
                       <span className="coutnt"> {count} </span>
                       <span onClick={(e) => {
                           e.stopPropagation()
                           ShoppingPATCH(item.id, 1).then(r => {
                               if (r.status == 200) {
                                   setCount(count + 1)
                                   onCount(item.id, count + 1)
                               }
                           })
                       }}>+</span>

                   </div>}/>
    </SwipeAction>);
};


export default function () {
    const {shoppingCount, setShoppingCount} = useContext(User)
    const navigate = useNavigate();
    const [shopping, setShoppoing] = useState([]) //购物车
    const [onGoods, setOnGoods] = useState([])    //选择商品
    const [price, setPrice] = useState(0)    //总金额
    const [count, setCount] = useState(0)    //件数
    const [load, setLoad] = useState(0) //点击更新
    const firstRenderRef = useRef(true);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (firstRenderRef.current) {
            ShoppingGET(searchParams.get('scope')).then(async r => {
                let data = await r.json()
                setShoppoing(data)
                console.log('购物车', data)
            })
            ShoppingGETNumber(searchParams.get('scope')).then(async r => {
                setShoppingCount(await r.json())
            })
            firstRenderRef.current = false
        }

    }, [])
    useEffect(() => {
        let price = 0;
        let count = 0
        onGoods.map(goods => {
            count += goods.count
            price += goods.price[goods.priceKey].price * goods.count
        })
        setCount(count)
        setPrice(price)
    }, [load])

    // 更新选中商品
    function upOnGoods(id, n) {
        onGoods.map((goods, i) => {
            if (goods.id == id) {
                goods.count = n
                setLoad(load + 1)
            }
        })
    }


    return <div className='shopping'>
        {shopping.length ? <Checkbox.Group onChange={val => {
            setOnGoods(val)
            setLoad(load + 1)
        }}>
            {shopping.map((item, i) => <List key={"goods"+i} header={item.store} key={item.id}>
                {item.goods.map((goods, _i) => (<ListItemWithCheckbox key={'list'+_i} item={goods} rmCount={async (goods) => {
                    setShoppingCount(shoppingCount - 1)
                    delete shopping[i].goods[_i]
                    await ShoppingDELETE(goods.id)
                }} onCount={(id, n) => {
                    // console.log('更新数量', id, n)
                    upOnGoods(id, n)
                }}/>))}
            </List>)}
        </Checkbox.Group> : <Empty description='暂无数据' />}
        {/*选中商品*/}

        <div style={{height: '120px'}}></div>
        {/*购物结算*/}
        {count ? <div className="shoppingCat">
            <p> 合计: <span>{count}</span> 件</p>
            <p> 总计: <span>{price}</span> ¥</p>
            <div className="shoppingBtn">
                <Button size='middle' color='warning' onClick={() => {
                    let scope =Number(localStorage.getItem('scopeId'))
                    console.log('结算商品', scope,onGoods)
                    OrderPOST({scope:scope,goods:onGoods}).then(async r => {
                        let data = await r.json()
                        navigate(`/pay?order=${data._id}`)
                        console.log('结算订单', data)
                    })
                }}>
                    去结算
                </Button>
            </div>
        </div> : ''}


    </div>
}