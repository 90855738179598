import {Badge, Tabs, Steps, List, Image, Button, Card, PullToRefresh, Empty} from "antd-mobile";
import React, {useEffect, useRef, useState} from "react";
import {BillPlanPATCH, Store, StoreGet} from "../../model/store";
import {UserScope} from "../../model/user";

const {Step} = Steps
const Height = `${window.innerHeight - 109}px`

export default function () {
    const [load, setLoad] = useState(1)
    const [bill, setBill] = useState([])
    const [billB, setBillB] = useState([])

    useEffect(() => {
        Store().then(async r => {
            let storeData = await r.json()
            localStorage.setItem('storeId', storeData._id)
            UserScope(storeData.scope).then(async r => {
                let data = await r.json()
                console.log('店铺区域', data)
                localStorage.setItem('storeScope', data.id)
                localStorage.setItem('storeScopeName', data.name)
            })
            StoreGet(storeData._id, 1).then(async r => {
                let data = await r.json()
                setBill(data)
                console.log('外送', data)
            })
            StoreGet(storeData._id, 2).then(async r => {
                let data = await r.json()
                setBillB(data)
                console.log('自取', data)
            })
        })

    }, [load])
    return <PullToRefresh onRefresh={() => setLoad(load + 1)} style={{height: Height}}>
        <Tabs className='orderCard'>
            <Tabs.Tab
                title={<Badge content={bill.length ? bill.length : ''} style={{'--right': '-10px', '--top': '8px'}}>
                    外送
                </Badge>} key='fruits'>
                <div style={{height: Height,overflow:"auto"}}>
                    {bill.length ? bill.map(item => <OrderItem data={item} onBillPlan={(id, plan) => {
                        BillPlanPATCH(id, plan).then(async r => {
                            if (r.status == '200') {
                                setLoad(load + 1)
                            }
                        })
                    }}/>) : <Empty description='暂无数据'/>}
                </div>
            </Tabs.Tab>
            <Tabs.Tab
                title={<Badge content={billB.length ? billB.length : ''} style={{'--right': '-10px', '--top': '8px'}}>
                    自取
                </Badge>} key='vegetables'>
                <div style={{height: Height,overflow:"auto"}}>
                    {billB.length ? billB.map(item => <OrderItem data={item} onBillPlan={(id, plan) => {
                        BillPlanPATCH(id, plan).then(async r => {
                            if (r.status == '200') {
                                setLoad(load + 1)
                            }
                        })
                    }}/>) : <Empty description='暂无数据'/>}
                </div>
            </Tabs.Tab>
        </Tabs>
    </PullToRefresh>
}

function OrderItem(props) {
    let {data, onBillPlan} = props
    return <Steps direction='vertical' className={'orderBottom'}>
        <Step
            title={`取件码:${data.code}`}
            status='finish'
            description={<div>
                <List>
                    {data.goods.map(goods => <List.Item prefix={<GoodsImg url={goods.img}/>}
                                                        description={goods.text + '¥ ' + goods.count}
                                                        children={goods.title} extra={goods.money + " ¥"}/>)}
                </List>
                <h4>合计:{data.money} 元</h4>
                <div className="orderBtn">
                    <Button size='small'>其他</Button>
                    <a href={'tel:'+data.tel}><Button size='small'>联系顾客</Button></a>
                    {data.plan == 1 ? <Button size='small' color='primary'
                                              onClick={() => onBillPlan(data._id, 2)}>出货</Button> : ''}
                </div>
            </div>}
        />
        <Step
            title='备注'
            status='wait'
            description={<div>
                <h3>{data.remark}</h3>
            </div>}
        />
        {/*<Step*/}
        {/*    title='货款'*/}
        {/*    status='wait'*/}
        {/*    description={data.plan==1?"还未出货":"取走后到账"}*/}
        {/*/>*/}
    </Steps>
}

function GoodsImg(props) {
    return <Image
        src={props.url}
        style={{borderRadius: 3}}
        fit='cover'
        width={30}
        height={30}
    />
}