import {
    Badge,
    Button,
    Form,
    Image,
    Input,
    List,
    Stepper,
    Tabs,
    Dialog,
    SwipeAction, Popup, FloatingBubble, ImageUploader, Empty
} from "antd-mobile";
import React, {useEffect, useRef, useState} from "react";
import {AddCircleOutline, PictureOutline, ReceiptOutline, ScanCodeOutline} from "antd-mobile-icons";
import {
    Authorization,
    AuthorizationCOS, CosImg, COSimg,
    GoodsLI,
    GoodsLIPlan,
    GoodsPATCH,
    GoodsRM,
    POSTGoods,
    POSTGoodsImg
} from '../../model/store'
import {SkeletonDIV} from "../../utils";

export default function () {
    const [visible1, setVisible1] = useState(false)
    const [goodsA, setGoodsA] = useState([])    //在售商品
    const [goodsB, setGoodsB] = useState([])    //下架商品
    const [goodsUP, setGoodsUP] = useState({})   //修改商品
    const firstRenderRef = useRef(true);
    const [load, setLoad] = useState(1)
    useEffect(() => {
        GoodsLI().then(async r => {
            setGoodsA(await r.json())
        })
        GoodsLIPlan(1).then(async r => {
            setGoodsB(await r.json())
        })
    }, [load])
    const rightActions = [
        {
            key: 'delete',
            text: '下架',
            color: 'danger',
        },
    ];


    const rightGoods = [
        {
            key: 'mute',
            text: '修改信息',
            color: 'warning',
        },
        {
            key: 'unsubscribe',
            text: '上架',
            color: 'light',
        },
        {
            key: 'delete',
            text: '删除',
            color: 'danger',
        },
    ];
    return <div>
        <Tabs>
            <Tabs.Tab title={<Badge content={goodsA.length} style={{'--right': '-10px', '--top': '8px'}}>
                在售
            </Badge>} key='fruits'>
                {goodsA.length ? <List>
                    {goodsA.map((item) => <SwipeAction key={item._id} rightActions={rightActions} onAction={(e) => {
                        console.log("下架", e.key)
                        if (e.key == 'delete') {
                            GoodsPATCH(item._id, {plan: -1}).then(r => {
                                console.log(r.status)
                                setLoad(load + 1)
                            })
                        }
                    }}>
                        <List.Item prefix={<GoodsImg url={item.imgArr[0].url}/>}
                                   description={item.price.map(t => t.size + t.price + '¥ /')} children={item.title}
                                   extra={"售出:" + item.sales}/>
                    </SwipeAction>)}
                </List> : <Empty description='暂无数据' />}
            </Tabs.Tab>
            <Tabs.Tab title={<Badge content={goodsB.length} style={{'--right': '-10px', '--top': '8px'}}>
                线下
            </Badge>} key='vegetables'>
                {goodsB.length ? <List>
                    {goodsB.map(item => <SwipeAction key={item._id} rightActions={rightGoods} onAction={(e) => {
                        console.log("下架", e.key)
                        if (e.key == 'unsubscribe') {
                            GoodsPATCH(item._id, {plan: 0}).then(r => {
                                console.log(r.status)
                                setLoad(load + 1)
                            })
                        }
                        if (e.key == 'mute') {
                            setGoodsUP(item)
                            setVisible1(true)
                        }
                        if (e.key == 'delete') {
                            GoodsRM(item._id).then(r=>setLoad(load+1))
                        }
                    }}>
                        <List.Item prefix={<GoodsImg url={item.imgArr[0].url}/>}
                                   description={item.price.map(t => t.size + t.price + '¥ /')} children={item.title}
                                   extra={item.plan==0?"审核中" :"下架中"}/>
                    </SwipeAction>)}
                </List> : <Empty description='暂无数据' />}
                <FloatingBubble
                    axis='xy'
                    magnetic='x'
                    style={{
                        '--initial-position-bottom': '124px',
                        '--initial-position-right': '24px',
                        '--edge-distance': '24px',
                    }}
                    onClick={() => {
                        setVisible1(true)
                        setGoodsUP({})
                    }}
                >
                    <ScanCodeOutline fontSize={32}/>
                </FloatingBubble>
                <PostGoods visible1={visible1} setVisible1={setVisible1} goods={goodsUP} setLoad={setLoad} load={load} />
            </Tabs.Tab>
        </Tabs>
    </div>
}


function extraText(item){
    switch (item.plan){
        case -1:
            return "下架中"
            break;
        case 0:
            return "审核"
            break;
    }
}

// 上传商品
function PostGoods(props) {
    const {visible1, setVisible1, goods,load,setLoad} = props
    const [fileList, setFileList] = useState([])
    const [form] = Form.useForm()
    useEffect(() => {
        if (goods.title) {
            form.setFieldsValue(goods)
        } else {
            form.resetFields()
        }
    }, [goods]);
    return <Popup
        visible={visible1}
        onMaskClick={() => {
            setVisible1(false)
        }}
        onClose={() => {
            setVisible1(false)
        }}
        bodyStyle={{minHeight: '40vh'}}
    >
        <Form
            layout='vertical'
            form={form}
            footer={
                <Button block type='submit' color='primary' size='large'>
                    提交
                </Button>
            }
            onFinish={(valuer) => {
                if (goods.title) {
                    valuer.plan=-1
                    GoodsPATCH(goods._id,valuer).then(r => {
                            console.log('更新商品',valuer)
                            form.resetFields()
                            setVisible1(false)
                            setLoad(load+1)
                        }
                    )
                } else {
                    valuer.scope = Number(localStorage.getItem('storeScope'))
                    console.log('上传商品',valuer)
                    POSTGoods(valuer).then(async r => {
                        console.log(await r.json())
                        setVisible1(false)
                        form.resetFields()
                        setLoad(load+1)
                    })
                }
            }}
        >

            <Form.Item
                name='title'
                label='商品标题'
                rules={[{required: true, message: '姓名不能为空'}]}
            >
                <Input onChange={console.log} placeholder='请输入商品基本描述'/>
            </Form.Item>

            <Form.Array
                label='商品规格'
                name='price'
                renderAdd={() => <span>
                              <AddCircleOutline/> 添加商品规格
                            </span>}
                renderHeader={(i, r) => {
                    return <>
                        <span>规格 {i.index + 1}</span>
                        <a onClick={() => r.remove(i.index)} style={{float: 'right'}}>
                            删除
                        </a>
                    </>
                }}
            >
                {fields =>
                    fields.map(({index}) => (
                        <div style={{display: "flex"}}>
                            <Form.Item
                                name={[index, 'size']}
                                label='规格'
                                rules={[{required: true, message: '不能为空'}]}
                            >
                                <Input placeholder='请输入规格'/>
                            </Form.Item>
                            <Form.Item name={[index, 'price']} label='售价'
                                       rules={[{required: true, message: '不能为空'}]}>
                                <Input type='number' placeholder='请输入售价'/>
                            </Form.Item>
                            <Form.Item name={[index, 'stock']} label='库存'
                                       rules={[{required: true, message: '不能为空'}]}>
                                <Stepper min={1}/>
                            </Form.Item>
                        </div>
                    ))
                }

            </Form.Array>

            <Form.Item
                name='imgArr'
                label='图片上传'
                rules={[{required: true, message: '第一张为封面'}]}
            >
                <ImageUploader
                    value={fileList}
                    onChange={setFileList}
                    upload={async (file) => {
                        let url = await CosImg(file)
                        return {url: url.url}
                    }}
                />
            </Form.Item>


        </Form>
    </Popup>
}


function GoodsImg(props) {
    const {url} = props
    return <Image
        src={url}
        style={{borderRadius: 3}}
        fit='cover'
        width={30}
        height={30}
    />
}