import {Button, Empty, List,Dialog} from "antd-mobile";
import React, {useEffect, useState} from "react";
import {AddressDELETE, AddressGet} from "../../model/user";

export default function Address(){
    const [address, setAddress] = useState();
    const [loading, setLoading] = useState(1);
    useEffect(() => {
        AddressGet().then(async r => {
            let data = await r.json()
            setAddress(data)
            console.log(data)
        })
    }, [loading]);
    return <List>
        {address&&address.length? address.map(item=> <List.Item title={item.address}
                                                description={item.tel} children={item.name}
                                                extra={<Button size='small' color='danger' onClick={()=> Dialog.confirm({
                                                    content: '删除后不可恢复',
                                                    closeOnAction: true,
                                                    actions:[
                                                        [
                                                            {
                                                                key: 'cancel',
                                                                text: '取消',
                                                            },
                                                            {
                                                                key: 'delete',
                                                                text: '删除',
                                                                bold: true,
                                                                danger: true,
                                                            },
                                                        ],
                                                    ],
                                                    onConfirm:()=>AddressDELETE(item._id).then(r => {
                                                        if(r.status == 204){
                                                            setLoading(loading+1)
                                                        }
                                                    })
                                                })} > 删除 </Button>}/>):<Empty description='暂无数据' />}

    </List>
}