import {Outlet, useNavigate} from "react-router-dom";
import {Button, TabBar} from 'antd-mobile'
import {
    BillOutline,
    EditSFill,
    EnvironmentOutline,
    HeartOutline,
    LocationFill,
    TeamFill,
    UserOutline
} from "antd-mobile-icons";
import {useEffect, useState} from "react";
import {GetUrlRelativePath} from "../../utils";
export default function Delivery(){
    const navigate = useNavigate();
    const [rKey, setrKey] = useState("");
    useEffect(() => {
        let urlKey = `${GetUrlRelativePath()}`
        setrKey(urlKey)
    }, []);
    return <div>
        <Outlet/>
        <TabBar  className='AppTabBar' activeKey={rKey} onChange={(value)=> {
            navigate(value)
            setrKey(value)
        }}>
            {tabs.map(item => (
                <TabBar.Item
                    key={item.key}
                    icon={item.icon}
                    title={item.title}
                    badge={item.badge}
                />
            ))}
        </TabBar>
    </div>
}

let tabs = [
    {
        key: '/delivery',
        title: '配送',
        icon: (active)=> <BillOutline />
    },
    {
        key: '/delivery/me',
        title: '我的',
        icon: (active) =><UserOutline /> ,
    },
]