import {Button, Form, Input, List, Popup, Radio, Space, Switch, TextArea, Toast, Dialog, Picker} from "antd-mobile";
import React, {useEffect, useRef, useState} from "react";
import {Address, AddressGet, AddressPost, OrderIdGET, OrderPATCH, ShoppingPATCH, ShoppingPOST} from "../../model/user";
import {json, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {SkeletonDIV, wxPay} from "../../utils";

export default function (props) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [visible, setVisible] = useState(false)
    const [value, setValue] = useState(1)
    const [address, setAddress] = useState({})
    const [address_, setAddress_] = useState(false)
    const [addressArr, setAddressArr] = useState([])
    const [delivery, setDelivery] = useState(0)
    const rest = useRef(true)
    const [form] = Form.useForm()
    const [formAddress]= Form.useForm()
    const params = useParams();
    useEffect(() => {
        if (rest.current) {

            let order = searchParams.get('order')

            OrderIdGET(order).then(async r => {
                let obj = await r.json()
                setValue(obj)
                if (obj.address) {
                    setAddress(obj.address)
                }
                form.setFieldsValue(obj)
                console.log('预支付订单', obj)
            })
            AddressGet().then(async r => {
                let addArr = []
                let data = await r.json()
                data.map((item) => {
                    addArr.push({
                        label: `${item.name} (${item.tel})-${item.address}`,
                        value: item
                    })
                })
                addArr.push({ label: '添加新地址', value: 'new' },)
                setAddressArr([addArr])
                console.log("收货地址",data)
            })
            rest.current = false
            return;
        }
    }, [])
    return <div className="pay">
        {value._id ? <Form
            layout='horizontal'
            form={form}
            footer={delivery?
                <Button block type='submit' color='primary' size='large'>
                    {delivery == 1 ? `仅付配送费${value.deliveryMoney}` : '到店现付'}
                    {/*到店取货时自付 {delivery == '1' ? value.money + value.deliveryMoney : value.money}¥*/}
                </Button>:''
            }
            onFinish={(val) => {
                if (!address.name && val.delivery == 1) {
                    Toast.show({
                        content: '收货地址错误,只能上门自取',
                    })
                    return
                }
                val.address = address
                console.log(val, val.delivery,address)
                OrderPATCH(value._id, val).then(async r => {
                    if (r.status == 200) {
                        OrderIdGET(value._id).then(async r => {
                            let obj = await r.json()
                            console.log("修改支付的订单", obj)
                            if (obj.delivery == 1) {
                                let sign = await wxPay(value)
                                console.log('支付签名', sign)
                                window.wx.chooseWXPay({
                                    appId: sign.appId,
                                    timestamp: sign.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                                    nonceStr: sign.nonceStr, // 支付签名随机串，不长于 32 位
                                    package: sign.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                                    signType: sign.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                                    paySign: sign.paySign, // 支付签名
                                    success: function (res) {
                                        // 支付成功后的回调函数
                                        Dialog.alert({
                                            content: '支付成功',
                                            onConfirm: () => {
                                                navigate('/order/c',{replace:true})
                                            },
                                        })
                                        console.log('支付', res)
                                    },
                                    file: function (err) {
                                        Toast.show({
                                            content: '支付错误,返回首页',
                                        })
                                        navigate(`/?scope=${localStorage.getItem('scope')}`)
                                    }
                                });
                            } else {
                                navigate('/order/b',{replace:true})
                            }
                        })
                    } else {
                        navigate(-1)
                    }
                })
            }}>
            {value.bill.map(item => <List header={item.store}>
                {item.goods.map((goods, i) =>
                    <List.Item key={'goods' + i} title={goods.title} description={goods.text + '¥ *' + goods.count}
                               extra={goods.money + "¥"}/>)}
            </List>)}


            <Form.Item name='remark' label='备注' help='备注所有店铺可见'>
                <TextArea
                    placeholder='备注所有店铺可见'
                    maxLength={100}
                    rows={2}
                    showCount
                />
            </Form.Item>
            <Form.Item
                name='delivery'
                label='配送方式'
                childElementPosition='right'
                rules={[{required: true, message: '请选择收货方式'}]}
            >
                <Radio.Group value={delivery} onChange={(e) => {
                    if(e == 1){
                        setVisible(true)
                        // formAddress.setFieldsValue({address:localStorage.getItem("storeScopeName")+"-"})
                    }
                    setDelivery(e) }
                }>
                    <Radio value='1'><span>配送上门 </span></Radio>
                    <Radio style={{marginLeft: '10px'}} value='2'><span>到店自取</span></Radio>
                </Radio.Group>
            </Form.Item>

            {delivery == 1 ? <Form.Item
                name='address'
                label='收货地址'
                childElementPosition='right'
                rules={[{required: false, message: '请选择收货地址'}]}
            >
                <div style={{display:"flex"}}>
                    <span style={{fontSize:"12px"}}> {address.name} <br/> {address.tel}
                        <br/> {localStorage.getItem("storeScopeName")+'-'+address.address}</span>
                    <Button size='small' color='primary'
                            fill='none'
                            onClick={() => setVisible(true)}>选择地址</Button>
                </div>

            </Form.Item> : '' }
            {delivery ==2? <Form.Item
                name='tel'
                label='联系电话'
                childElementPosition='right'
                rules={[{required: true, message: '请选择收货地址'}]}
            >
                <Input placeholder='请输入联系电话' type={"Number"}  />
            </Form.Item>:''}
            {delivery == 1 ? <Form.Item
                name='delivery'
                label='配送费'
                childElementPosition='right'
            >
                <p> {value.bill.length}家店- {value.count}件商品- 共计 {value.deliveryMoney}¥ </p>

            </Form.Item> : ''}

        </Form> : <SkeletonDIV/>}

        <Picker columns={addressArr} visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                onConfirm={(v,i) => {
                    if(v[0] == 'new'){
                        setAddress_(true)
                        console.log("选择地址",v)
                    }else {
                        setAddress(v[0])
                    }

                }}/>
        <Popup visible={address_}
               onMaskClick={() => {
                   setAddress_(false)
               }}
               onClose={() => {
                   setAddress_(false)
               }}
               bodyStyle={{minHeight: '40vh'}}>
            <Form
                layout='vertical'
                form={formAddress}
                footer={
                    <Button block type='submit' color='primary' size='large'>
                        提交
                    </Button>
                }
                onFinish={(value)=>{
                    console.log(value)
                    AddressPost(value).then(async r => {
                        let data = await r.json()
                        if(r.status == 200){
                            AddressGet().then(async r => {
                                let addArr = []
                                let data = await r.json()
                                data.map((item) => {
                                    addArr.push({
                                        label: `${item.name} (${item.tel})-${item.address}`,
                                        value: item
                                    })
                                })
                                addArr.push({ label: '添加新地址', value: 'new' },)
                                setAddressArr([addArr])
                                setAddress_(false)
                                setVisible(true)
                                console.log("收货新地址",data)
                            })
                        }
                        console.log(data)
                    })
                }}
            >
                <Form.Header>添加新地址</Form.Header>
                <Form.Item
                    name='name'
                    label='姓名'
                    rules={[{ required: true, message: '姓名不能为空' }]}
                >
                    <Input onChange={console.log} placeholder='请输入姓名' />
                </Form.Item>
                <Form.Item
                    name='tel'
                    label='电话'
                    rules={[{ required: true, message: '姓名不能为空' }]}
                >
                    <Input onChange={console.log} type={"number"} placeholder='请输入电话' />
                </Form.Item>
                <Form.Item name='address' label={`配送地址: ${localStorage.getItem("storeScopeName")}`}  rules={[{ required: true, message: '地址不能为空' }]}>
                    <TextArea
                        placeholder='请输入地址'
                        maxLength={50}
                        rows={2}
                        showCount
                    />
                </Form.Item>
            </Form>
        </Popup>
    </div>
}
