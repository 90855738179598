import {useNavigate} from "react-router-dom";
import {Avatar, Badge, List} from "antd-mobile";
import {useEffect, useRef, useState} from "react";
import {orderCount, UserMsg, UserScope} from "../../model/user";
import {DeliveryIncome} from "../../model/delivery";

const Height = window.innerHeight - 60
export default function () {
    const [user,setUser] = useState({})
    const [income,setIncome] = useState({})
    const navigate = useNavigate();
    const firstRenderRef = useRef(true);
    useEffect(() => {
        if (firstRenderRef.current){
            UserMsg().then(async r => {
                let user = await r.json()
                console.log('骑手用户信息',user)
                UserScope(user.deliveryScope).then(async r => {
                    let data = await r.json()
                    console.log('加载区域',data)
                    localStorage.setItem('delivery', data.name)
                    localStorage.setItem('deliveryId', data.id)
                })
                setUser(user)
            })
            DeliveryIncome().then(async r => {
                setIncome(await r.json())
            })
            firstRenderRef.current = false;
            return;
        }
    }, []);

    return <div className="My" style={{height: Height}}>
        <List mode='card' className='MyUser'>
            {user?  <List.Item
                style={{background: '#fcfcfc'}}
                prefix={<Badge
                    color='#87d068'
                    // content={Badge.dot}
                    content='骑手'
                    style={{'--top': '90%', '--right': '80%',}}
                >
                    <Avatar style={{'--size': '64px', 'borderRadius': 60}} src={user.imgUrl}/>
                </Badge>}
                description={'位置：' + localStorage.getItem('delivery')}
            >
                {user.deliveryCard}
            </List.Item>:''}

        </List>

        <div className='apph1'> 收银台</div>
        <div className='My_tabs'>
            <div className='My_tabs_item'>
                <span className='mt3'> {income.incomeA} <span className='mt2'>元</span> </span>
                <span className='mt1'>总收入</span>
            </div>
            <div className='My_tabs_item'>
                <span className='mt3'> {income.incomeB} <span className='mt2'>元</span> </span>
                <span className='mt1'>今日收入</span>
            </div>
            <div className='My_tabs_item'>
                <span className='mt3'>   {income.incomeC} <span className='mt2'>元</span> </span>
                <span className='mt1'>入账收入</span>
            </div>
        </div>

        <List mode='card'>
            <List.Item className='li_item' extra='查看' onClick={() => navigate('/delivery_order')}>
                可提现订单
            </List.Item>
            <List.Item className='li_item' extra='查看' onClick={() => navigate('/delivery_deposit')}>
                提现记录
            </List.Item>
            <List.Item className='li_item' extra='' onClick={() => {
            }}>
                骑手评价
            </List.Item>
        </List>

        <List mode='card'>
            <List.Item className='li_item' extra='1200' onClick={() => {
            }}>
                积分兑换
            </List.Item>
        </List>

        <List mode='card'>
            <List.Item className='li_item' onClick={() => {
            }}>联系客服</List.Item>
        </List>

        <div className='footer'> Store_v0.0.1</div>

    </div>
}