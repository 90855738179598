import {api} from "../utils";

// 参数获取使用范围
export const UserScope = (em) => fetch(api+`user/scope/${em}`)

// 登陆
export const UserLogin = (user) => fetch(api+"user/login",{
    method:"POST",
    headers: {
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(user)
})

export const orderCount = () => fetch(api+'order/orderCount',{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})


// 用户信息
export const UserMsg = () => fetch(api+"user/msg",{
    method:"POST",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

// 开通店铺
export const Store= (obj) => fetch(api+'store',{
    method:"POST",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(obj)
})

export const Delivery = (obj) => fetch(api+'user/delivery',{
    method:"POST",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(obj)
})
// 店铺列表
export const  StoreLi = (schoolId) =>fetch(api+`store/li/${schoolId}`)

// 商品列表
export const GoodsLi = (t) => fetch(api+`goods/li/${t}`)

// 添加购物车
export const ShoppingPOST = (goods) => fetch(api+'shopping',{
    method:"POST",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(goods)
})

// 查询购物车
export const ShoppingGET= (scope) => fetch(api+`shopping/?scope=${scope}`,{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})
export const ShoppingGETNumber= (scope) => fetch(api+`shopping/number?scope=${scope}`,{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

//移除购物车
export const ShoppingDELETE= (id) => fetch(api+`shopping/${id}`,{
    method:"DELETE",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

//修改购物车数量
export const ShoppingPATCH= (id,n) => fetch(api+`shopping/${id}/${n}`,{
    method:"PATCH",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

// 用户下单
export const OrderPOST = (goods)=> fetch(api+'order',{
    method:"POST",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(goods)
})


//查询用户订单
export const OrderIdGET = (id) => fetch(api+`order/id/${id}`,{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

// 查询用户订单
export const OrderGET = (plan) => fetch(api+`order?plan=${plan}`,{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

// 查询用用户订单
export const OrderGETDelivery = (delivery) => fetch(api+`order/userDelivery/${delivery}`,{
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})



// 删除订单
export const OrderDELETE = (id) => fetch(api+`order/${id}`,{
    method:"DELETE",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

// 修改订单备注和地址
export const OrderPATCH= (id,obj) => fetch(api+`order/${id}`,{
    method:"PATCH",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(obj)
})

// 创建地址
export const AddressPost = (val) => fetch(api+'address',{
    method:"POST",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
    body:JSON.stringify(val)
})

// 查询地址
export const AddressGet = () => fetch(api+'address',{
    method:"GET",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
})

// 删除地址
export const AddressDELETE = (id) => fetch(api+`address/${id}`,{
    method:"DELETE",
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    }
})

