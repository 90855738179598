import {Image, List} from "antd-mobile";
import React, {useEffect, useState} from "react";
import {BillLiPLanGet} from "../../model/store";
import {timestampFormat} from "../../utils";

export default function (){
    const [bill,setBIll] = useState([])
    useEffect(()=>{
        BillLiPLanGet(2).then(async r=>{
            let data = await r.json()
            console.log('流水单',data)
            setBIll(data)
        })
    },[])
    return <div>
        {bill.length?<List>
            {bill.map(item=>
                // <List.Item description={item.goods.map(goods=> `${goods.title}-${goods.text}¥ * ${goods.count}`)} children={`${item.delivery==1?'配送':'自取'}单:${item.code}-${item.money}¥`} extra={timestampFormat(item.updated)}/>)}
                <List.Item title={item.delivery == 1 ? '配送单:':'自取单:'+item.code} description={item.goods.map(goods => <p style={{margin:0}}>{goods.title +'-'+ goods.text} *  {goods.count}</p>)  }
                           children={`合计:${item.money}¥`} extra={timestampFormat(item.updated)}/>)}
        </List>:''}

    </div>
}

function GoodsLi(){
    return <div className={'orderGoodsLi'}>
        <p>规格- 3份</p>
        <p>规格- 3份</p>
        <p>规格- 3份</p>
    </div>
}