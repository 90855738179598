import {useFetcher, useNavigate} from "react-router-dom";
import {Avatar, Badge, Button, Form, Input, List, Popup, Empty, ImageUploader} from "antd-mobile";
import {AppOutline} from "antd-mobile-icons";
import React, {useEffect, useRef, useState} from "react";
import {Delivery, orderCount, OrderGET, Store, UserLogin, UserMsg} from "../../model/user";
import {api} from "../../utils";
import {DeliveryGet} from "../../model/delivery";
import {CosImg} from "../../model/store";

const Height = window.innerHeight - 60

export default function () {
    const [licence, setLicence] = useState([])   //营业执照
    const [fm, setFm] = useState([])    //店铺封面
    const [deliveryImg,setDeliveryImg] = useState([]) //身份证
    const [deliveryMa,setDeliveryMa] = useState([]) //收款码
    const [user,setUser] = useState({})
    const [count, setCount] = useState({})
    const [store,setStore] = useState(false)
    const [delivery,setDelivery] = useState(false)
    const navigate = useNavigate();
    const firstRenderRef = useRef(true);
    useEffect(() => {
        if (firstRenderRef.current){
            UserMsg().then(async r => {
                if(r.status != 200){
                    localStorage.removeItem('token')
                    return
                }
                let data = await r.json()
                setUser(data)

            })
            orderCount().then(async r=>{
                setCount(await r.json())
            })
            firstRenderRef.current = false;
            return;
        }
    }, []);
    return <div className="My" style={{height: Height}}>
        {user.id?<List mode='card' className='MyUser'>
            <List.Item
                style={{background: '#fcfcfc'}}
                prefix={<Badge
                    color='#87d068'
                    // content={Badge.dot}
                    content='vip'
                    style={{'--top': '90%', '--right': '80%',}}
                >
                    <Avatar style={{'--size': '64px', 'borderRadius': 60}} src={user.imgUrl}/>
                </Badge>}
                description={'ID：' + user.id}
            >
                {user.nickname}
            </List.Item>
        </List>: <Empty description='暂无数据' />}


        <div className='MyTab'>
            <div className='MyTab_icon' onClick={() => navigate('/order/a')}>
                <AppOutline fontSize={24}/>
                <Badge content={count.order1?count.order1:''}>
                    未支付
                </Badge>
            </div>
            <div className='MyTab_icon' onClick={() => navigate('/order/b')}>
                <AppOutline fontSize={24}/>
                <Badge content={count.order3?count.order3:''}>
                    自取
                </Badge>
            </div>
            <div className='MyTab_icon' onClick={() => navigate('/order/c')}>
                <AppOutline fontSize={24}/>
                <Badge content={count.order2?count.order2:''}>
                    外送
                </Badge>
            </div>
            <div className='MyTab_icon' onClick={() => navigate('/order/d')}>
                <AppOutline fontSize={24}/>
                <Badge content={count.order0?count.order0:''}>
                    全部
                </Badge>
            </div>
        </div>

        <List mode='card'>
            <List.Item className='li_item' extra='查看' onClick={() => navigate('/address')}>
                地址栏
            </List.Item>
        </List>

        <List mode='card'>
            {user.storePlan?<List.Item className='li_item' extra={user.storePlan == 1?'审核中':'营业中'} onClick={() =>{
                if(user.storePlan == 1){
                    alert('店铺审核中')
                }else {
                    navigate("/store")
                }
            }}>
                管理店铺
            </List.Item>:<List.Item className='li_item' extra='' onClick={() =>setStore(true) }>
                开通店铺
            </List.Item>}

            <List.Item className='li_item' extra={deliveryPlanText(user.deliveryPlan)} onClick={() => {
                if(user.deliveryPlan == 0){
                    setDelivery(true)
                }
                if(user.deliveryPlan == -1) {
                    alert("联系管理员开通")
                }
                if(user.deliveryPlan == 2) {
                    navigate("/delivery")
                }
            }}>
                成为骑手
            </List.Item>
        </List>

        <List mode='card'>
            <List.Item className='li_item' onClick={() => {
            }}>联系客服</List.Item>
        </List>

        <Popup
            visible={store}
            onMaskClick={() => {
                setStore(false)
            }}
            onClose={() => {
                setStore(false)
            }}
            bodyStyle={{ minHeight: '50vh' }}
        >
            <Form layout='horizontal' mode='card'
                  onFinish={(value)=>{
                      value.scope =  Number(localStorage.getItem('scope'))
                      value.boss = user._id
                      value.fm = fm[0].url
                      value.licence = licence[0].url
                      value.tel= Number(value.tel)
                      Store(value).then(res=>{
                          if(res.status==200){
                              setStore(false)
                              UserMsg().then(async r => {
                                  let data = await r.json()
                                  console.log('更新用户信息',data)
                                  setUser(data)
                              })
                          }
                      })
                      console.log('注册店铺', value)
                  }}
                  footer={
                      <Button block type='submit' color='primary' size='large'>
                          提交
                      </Button>
                  }
                >
                <Form.Header>开店报名</Form.Header>
                <Form.Item label='店铺名'  name='name' rules={[{required: true, message: '请输入'}]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                <Form.Item label='店铺照片'  name='fm' rules={[{required: true, message: '请上传'}]}>
                    <ImageUploader
                        value={fm}
                        maxCount={1}
                        onChange={setFm}
                        upload={async (file) => {
                            console.log(file)
                            let url = await CosImg(file)
                            return {url: url.url}
                        }}
                    />
                </Form.Item>
                <Form.Item label='手机号'  name='tel' rules={[{required: true, message: '请上传'}]}>
                    <Input placeholder='请输入'  />
                </Form.Item>
                <Form.Item label='身份证名'  name='card' rules={[{required: true, message: '请上传'}]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                <Form.Item label='身份号码'  name='cardID' rules={[{required: true, message: '请上传'}]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                <Form.Item label='营业执照图片'  name='licence' rules={[{required: true, message: '请上传营业执照'}]}>
                    <ImageUploader
                        value={licence}
                        maxCount={1}
                        onChange={setLicence}
                        upload={async (file) => {
                            console.log(file)
                            let url = await CosImg(file)
                            return {url: url.url}
                        }}
                    />
                </Form.Item>
                <Form.Header />
            </Form>
        </Popup>


        <Popup
            visible={delivery}
            onMaskClick={() => {
                setDelivery(false)
            }}
            onClose={() => {
                setDelivery(false)
            }}
            bodyStyle={{ minHeight: '46vh' }}
        >
            <Form layout='horizontal' mode='card'
                  onFinish={(value)=>{
                      value.deliveryScope =  Number(localStorage.getItem('scope'))
                      value.deliveryMa= deliveryMa[0].url
                      value.deliveryImg= [deliveryImg[0].url,deliveryImg[0].url]
                      Delivery(value).then(r=>{
                          if(r.status==200){
                              UserMsg().then(async r => {
                                  let data = await r.json()
                                  setUser(data)
                              })
                              setDelivery(false)
                              alert('注册成功,等待反馈')
                          }
                      })
                      console.log("骑手信息",value)
                  }}
                  footer={
                      <Button block type='submit' color='primary' size='large'>
                          提交注册
                      </Button>
                  }
            >
                <Form.Header>申请成为骑手</Form.Header>
                <Form.Item label='身份证姓名'  name='deliveryCard' rules={[{required: true, message: '请输入身份证姓名'}]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                <Form.Item label='身份证号码'  name='deliveryCardID' rules={[{required: true, message: '请输入身份证姓号码'}]}>
                    <Input placeholder='请输入' />
                </Form.Item>
                <Form.Item label='身份证正反面'  name='deliveryImg' rules={[{required: true, message: '请上传身份证姓'}]}>
                    <ImageUploader
                        value={deliveryImg}
                        maxCount={2}
                        onChange={setDeliveryImg}
                        upload={async (file) => {
                            console.log(file)
                            let url = await CosImg(file)
                            return {url: url.url}
                        }}
                    />
                </Form.Item>
                <Form.Item label='手机号'  name='deliveryTel' rules={[{required: true, message: '请输入'}]}>
                    <Input placeholder='请输入'  />
                </Form.Item>
                <Form.Item label='微信收款码'  name='deliveryMa' rules={[{required: true, message: '请上传收款码'}]}>
                    <ImageUploader
                        value={deliveryMa}
                        maxCount={1}
                        onChange={setDeliveryMa}
                        upload={async (file) => {
                            console.log(file)
                            let url = await CosImg(file)
                            return {url: url.url}
                        }}
                    />
                </Form.Item>
                <Form.Header />
            </Form>
        </Popup>

        <div className='footer'> Store_v0.0.1</div>

    </div>
}

const  deliveryPlanText = (plan) => {
    switch (plan) {
        case 0:
            return '跑单赚钱'
            break;
        case 1:
            return '审核中'
            break;
        case 2:
            return '正常'
            break;
        case -1:
            return '封号中'
            break;

    }
}